import React from 'react';
import {Typography} from '@material-ui/core';
import bilde from '../img/allmøte gavil.jpg';
import { Button } from '@material-ui/core';
import sakspapirer from '../img/Saksliste vår 2024.pdf';

const img = bilde

const header = (
    <>
      <Typography variant='h2'>Robotnik Allmøte V2024</Typography>
      <Typography variant='h3'>06/03/24 - 12:00</Typography>
      <Typography variant='h5'>Påmeldingen er stengt</Typography>
      <Typography variant='h3'><a href="https://link.mazemap.com/VLROS3Co">U224</a></Typography>
    </>
  );

const button = (
        <Button variant="contained" color="primary" size="large" href={sakspapirer} download="Saksliste vår 2024.pdf">
            Sakspapirer Allmøte Våren 2024
        </Button>
);


const text = [
    <Typography variant='body1'>
        Med nytt semester godt igang er det duket for nytt Allmøte.{<br/>}{<br/>}

        I denne omgang er det en rekke viktige verv som skal velges inn.{<br/>}
        - Leder for Robotnik{<br/>}
        - Sosialansvarlig{<br/>}
        - Næringslivsansvarlig{<br/>}
        - Faddersjef{<br/>}

        Om det er noen du ser for deg at passer til disse vervene, eller om du selv er interessert, så oppfordres det til å ta kontakt med det sittende styret.{<br/>}
        
        Det vil bli servert pizza på møtet, så det er viktig at du melder deg på slik at vi får bestilt riktig mengde.{<br/>}
        Vi oppforderer alle til å møte opp og bruke stemmeretten sin.{<br/>}{<br/>}
        Vel møtt!{<br/>}{<br/>}

        
        

    <Button 
        variant="contained" 
        color="primary" 
        size="large" 
        href="https://forms.gle/z4avCKHg7Q3N1CRV8"
        style={{ fontSize: '20px', padding: '20px 40px', marginRight: '20px' }}
    >
    PÅMELDING
    </Button>
{button}
    </Typography>
];

const body = {img, header, text}
export default body