import React from 'react';
import {Typography, List, ListItem} from '@material-ui/core';
import robotnik_styre from '../img/robotnik_styre_2022.jpg';

const img = robotnik_styre

const header = 'Allmøte og nytt styre 2022'

const text = [
    <>
        <Typography variant='body1'>
                23. Februar avholdt Robotnik NMBU allmøte hvor nye medlemmer ble valgt inn i styre for 2022.
        </Typography>

        <Typography variant='body1'>
            De nye medlemmene i styret er:
        </Typography>

        <List>
            <ListItem>
                <Typography variant='body2'><b>Christianie torres som sosial ansvarlig.</b></Typography>
            </ListItem>
            <ListItem>
                <Typography variant='body2'><b>Christine Isaksen ble gjenvalgt som leder av styret.</b></Typography>
            </ListItem>
            <ListItem>
                <Typography variant='body2'><b>Jorid Holmen ble valgt som fadder sjef for høsten 2022.</b></Typography>
            </ListItem>
        </List>

        <Typography variant='body1'>
            Resten av styret fortsetter med Vilde Dale som næringsliv ansvarlig, Nada Mohammed som økonomi ansvarlig og William Dahl som administrasjons ansvarlig. 
        </Typography>

        <Typography variant='body1'>
            Tusen takk til alle våre medlemmer som møtte opp og brukte stemmeretten sin!
        </Typography>
    </>
]


const body = {img, header, text}
export default body