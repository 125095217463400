import ny_nettside from './ny_nettside'
import nytt_styre_2021 from './nytt_styre 2021'
import nytt_styre_2022 from './nytt_styre 2022'
import semesterplan_v22 from './semesterplan_v22'
import nytt_styre_2022H from './nytt_styre_2022H'
import nytt_styre_2023H from './nytt_styre 2023H'
import semesterplan_v23 from './semesterplan_v23'

const news = {
    "0": semesterplan_v23,
    "1": nytt_styre_2023H,
    "2": nytt_styre_2022H,
    "3": nytt_styre_2022,
    "4": semesterplan_v22,
    "5": nytt_styre_2021,
    "6": ny_nettside,
}

export {news}