import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Typography, Grid, Container, Button} from '@material-ui/core';
import student_img from '../img/student.jpg'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        maxWidth: '1000px',
    },
    img: {
        height: '45vh',
        [theme.breakpoints.down("xs")]: {
            height: '20vh',
        },
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
    },
    section: {
        paddingTop: '2vh',
        paddingBottom: '2vh',
        borderBottom: '1px solid black',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export default function DenseAppBar() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container className={classes.root}>
                <Grid justifyContent="center" direction="column" container>
                    <Grid item>
                        <Typography variant='h2' align='center'>For studenter</Typography>
                    </Grid>
                    <Grid item className={classes.img}>
                        <img src={student_img} style={{width: "100%", height: "100%", objectFit: 'cover'}} alt='Student og lærer'/>
                    </Grid>
                    <Grid item className={classes.section}>
                        <Typography variant='body1'>
                            Mastergradsstudiet i anvendt robotikk er rettet mot deg som ønsker å jobbe med å drifte, 
                            utvikle eller forske på automasjon og robotikk.
                        </Typography>
                    </Grid>
                    <Grid item className={classes.section}>
                        <Typography variant='body1'>
                            I dag foregår robotisering og automasjon i nær sagt alle industrier. 
                            Med en mastergrad i robotikk vil du derfor kunne få muligheten til å jobbe i et bredt spekter av bedrifter og organisasjoner, 
                            med varierte arbeidsoppgaver.
                        </Typography>
                        <Typography variant='body1'>
                            Mastergraden i anvendt robotikk gir en grundig innføring i robotikk og automasjon. 
                            Studiet har et sterkt fokus på de anvendte fagene. Dette er fag som er viktige for å kunne designe, 
                            bygge og implementere robotsystemer i den virkelige verden.
                        </Typography>
                        <Typography variant='body1'>
                            Som robotikkstudent vil du lære å designe nye systemer, 
                            bygge disse systemene fysisk, samt å implementere og teste systemene ute i industrien eller andre steder. 
                            Som robotikkstudent jobber du med virkelige systemer på lab og i industrien fra dag én. 
                            Dette gir verdifull praktisk erfaring..
                        </Typography>
                        <Button variant="outlined" color="primary" align="center"
                            href="https://www.nmbu.no/studier/studietilbud/master-femarig/robotikk">
                                Anvendt robotikk - sivilingeniør på NMBU
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}
