import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Typography, Grid, Link} from '@material-ui/core';
import thorvald_logo from '../img/thorvald_logo.png'
import nmbu_logo from '../img/nmbu_logo.jpg'
import kameleon_logo from '../img/kameleon_logo.png'

//test


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    partner: {
        borderTop: '1px solid black',
        padding: '20%',
        width: '100%',
    },
    logo: {
        width: '100%',
    },
    header: {
        paddingBottom: '10%',
        paddingTop: '10%',
    }
});

const partnere = [
                {img: thorvald_logo, url: 'https://sagarobotics.com/'}, 
                {img: nmbu_logo, url: 'https://www.nmbu.no/'},
                {img: kameleon_logo, url: 'https://www.kameleonrobotics.no/'}
            ];

export default function Partnere() {
    const classes = useStyles();

    return (
        <React.Fragment>
        <Grid container direction="column" align="center" className={classes.root}>
            <Grid item padding="5%" className={classes.header}>
                <Typography variant="h2" className={classes.header}>Våre <br/> partnere</Typography>
            </Grid>
            {
                partnere.map((partner) => {return (
                    <Grid item className={classes.partner}>
                        <Link href={partner.url} target="_blank">
                            <img className={classes.logo} src={partner.img} alt="logo" />
                        </Link>
                    </Grid>
                )})
            }
        </Grid>
        </React.Fragment>
    )
}