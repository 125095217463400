import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Paper, Typography, Grid, Button} from '@material-ui/core';
import Partnere from '../components/våre_partnere';
import Nyheter from '../components/nyheter'
import thorvald_robots from '../img/thorvald_robots_filter.jpg'
import semesterProgram from '../img/semesterplan_h24.jpg'

const useStyles = makeStyles(theme => ({
  root: {
  },
  kontakt_oss: {
    marginTop: '5em',
    width: '20vw',
    fontSize: '1rem',
    padding: '1em',
    position: 'relative',
    top: '-2vh',
    [theme.breakpoints.down("xs")]: {
      fontSize: '1rem',
      width: '50vw',
    },
  },
  top: {
    backgroundImage: `url(${thorvald_robots})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: '5%',
    height: '25vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down("xs")]: {
      height: '85vh',
    },
  },
  header: {
    color: 'white', 
    fontSize: '2.5rem',
    fontWeight: 'bold',
    letterSpacing: '.2rem',
    position: 'relative',
    top: '-3vh',
    [theme.breakpoints.down("xs")]: {
      fontSize: '2rem',
    },
  },
  font: {
    fontSize: '1.5rem',
    color: 'white', 
    fontWeight: '500',
    [theme.breakpoints.down("xs")]: {
      fontSize: '1rem',
    },
  },
  nyheter: {
    margin: '5vh',
    width: '70%',
    [theme.breakpoints.down("xs")]: {
      margin: '0',
      width: '100%',
    },
  },
  partnere: {
    width: '15%',
    [theme.breakpoints.down("xs")]: {
      width: '100%',
    },
  },
  semesterProgram: {
    width: '50%',
      [theme.breakpoints.down("xs")]: {
          width: '100%',
      },
  }
}));

function Banner() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper className={classes.top}>
            {/* <Typography variant='h1'className={classes.header}>Robotnik NMBU</Typography> */}
            <Typography variant='h1'className={classes.header}>
              Linjeforeningen for studenter på 
              <br/> Anvendt Robotikk 
              <br/> ved NMBU
              {/* <br/> ved Norges miljø- og biovitenskaplige universitet */}
            </Typography>
            <Button href='/kontakt_oss' variant="contained" color='primary' className={classes.kontakt_oss}>Kontakt oss</Button>
      </Paper>
    </React.Fragment>
  )
}

export default function Home() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Paper className={classes.root}  elevation={0} square='true'>
              <Banner/>
              <Grid container direction='row' justifyContent='center'>
                <Grid item className={classes.nyheter} sx={1}>
                  <Nyheter/>
                </Grid>
                <Grid item className={classes.partnere} sx={1}>
                  <Partnere/>
                </Grid>
                <img src={semesterProgram} className={classes.semesterProgram} alt='semesterProgram'/>
              </Grid>
            </Paper>
        </React.Fragment>
    );
}
