import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Grid, Typography, Link} from '@material-ui/core';
import Social from './components/social';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'white',
    color: 'black',
    padding: '1%',
    height: '15vh',
    [theme.breakpoints.down("xs")]: {
      height: '30vh',
    },
  },
  grid: {
    display: 'flex',
    justifyContent: "space-between",
    height: '100%',
    [theme.breakpoints.down("xs")]: {
      justifyContent: 'center',
    },
  }
}));

export default function DenseAppBar() {
    const classes = useStyles();

    return (
        <React.Fragment>
          <Container className={classes.root}>
              <Grid container alignItems="center"  className={classes.grid} direction='row'>
                <Grid item>
                  <Typography variant='h5'>Robotnik NMBU</Typography>
                </Grid>
                <Grid item>
                  <Social/>
                </Grid>
                <Grid item>
                  <Typography variant='h5'>robotnik@nmbu.no</Typography>
                </Grid>
              
                <Link color='inherit' href="https://hinderaker-robotics.vercel.app/" style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                  <Typography variant='overline' style={{width: '100%', fontSize: '0.6rem'}} align='center'>
                      Made by Hinderaker Robotics
                  </Typography>
                </Link>
              </Grid>
          </Container>
        </React.Fragment>
    );
}
