import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './style/App.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import Header from './Header';
import Footer from './Footer';
import Home from './pages/Home';
import FOR_STUDENTER from './pages/For_studenter';
import FOR_BEDRIFTER from './pages/For_bedrifter';
import OM_OSS from './pages/Om_oss';
import OM_NMBU from './pages/Om_NMBU';
import KONTAKT_OSS from './pages/Kontakt_oss';
import EVENTS from './pages/Events';

const theme = createTheme({
  palette: {
      primary: {
        main: '#3d8e77',
      },
      secondary: {
        main: '#5dacbd',
      },
  },
});

// theme.palette.primary = '#3d8e77'

theme.typography.h2 = {
    fontSize: '3rem',
    marginTop: '5vh',
    marginBottom: '5vh',
    fontWeight: '500',
}

theme.typography.h3 = {
    fontSize: '2rem',
    marginTop: '5vh',
    marginBottom: '5vh',
    fontWeight: '500',
}

theme.typography.subtitle1 = {
    fontSize: '2rem',
    marginTop: '5vh',
    marginBottom: '5vh',
    fontWeight: 'bold',
}

theme.typography.body1 = {
    fontSize: '1.5rem',
    paddingTop: '2vh',
    paddingBottom: '2vh',
    fontWeight: '300',
    [theme.breakpoints.down("xs")]: {
      fontSize: '1.2rem',
    },
  };
  
theme.typography.body2 = {
    fontSize: '1rem',
}




class App extends React.Component {

  render() {
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <Header/>
          <Router>
            <div>
              <Routes>
                <Route exact path="/" element={<Home/>} />
                <Route exact path="/kontakt_oss" element={<KONTAKT_OSS/>} />
                <Route exact path="/om_oss" element={<OM_OSS/>} />
                <Route exact path="/om_nmbu" element={<OM_NMBU/>} />
                <Route exact path="/for_bedrifter" element={<FOR_BEDRIFTER/>} />
                <Route exact path="/for_studenter" element={<FOR_STUDENTER/>} />
                <Route exact path="/events" element={<EVENTS/>} />
              </Routes>
            </div>
          </Router>
          <Footer/>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default App;