import React from 'react';
import {Typography} from '@material-ui/core';
import bilde from '../img/robotnik_styre_2023H.jpg';

const img = bilde

const header = 'Robotnik Styret 2023'

const text = [
    <Typography variant='body1'>
        Det er valgt inn et nytt styre for Robotnik NMBU for høsten 2023.{<br/>}{<br/>}

        Vi takker de utgående styremedlemmene for innsatsen og ønsker det nye styret lykke til med arbeidet.{<br/>}

        Takk til alle medlemmer som møtte opp og brukte stemmeretten sin!
    </Typography>
]

const body = {img, header, text}
export default body