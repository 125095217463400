import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Typography, Grid, Link} from '@material-ui/core';
import ur_img from '../img/ur_byggningen.jpg'
import GoogleMaps from '../components/maps'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        maxWidth: '1000px',
    },
    img: {
        height: '45vh',
        [theme.breakpoints.down("xs")]: {
            height: '20vh',
        },
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
    },
    section: {
        paddingTop: '2vh',
        paddingBottom: '2vh',
        borderBottom: '1px solid black',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export default function DenseAppBar() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container className={classes.root}>
                <Grid justifyContent="center" direction="column" container>
                    <Grid item>
                        <Typography variant='h2' align='center'>Om NMBU</Typography>
                    </Grid>
                    <Grid item className={classes.img}>
                        <img src={ur_img} style={{width: "100%", height: "100%", objectFit: 'cover'}} alt='Student og lærer'/>
                    </Grid>
                    <Grid item className={classes.section}>
                        <Typography variant='body1'>
                            NMBU (Norges Miljø-og Biovitenskaplige Universitet) er et universitet i Ås kommune like utenfor Oslo. 
                        </Typography>
                    </Grid>
                    <Grid item className={classes.section}>
                        <Typography variant='body1'>
                            NMBU tilbyr studier og forskning som skal møte de globale spørsmålene innen miljø, bærekraftig utvikling, bedre folke- og dyrehelse, klimautfordringer, fornybare energikilder, matproduksjon og areal- og ressursforvalting. Universitet har i dag cirka 5000 studenter basert på 64 studieprogram.
                        </Typography>
                        <Typography variant='body1'>
                            En stor del av studentene kommer fra sivilingeniørstudier på Fakultet for Realfag og Teknologi (RealTek). Her utdannes det sivilingeniører innen blant annet Bygg, Industriell Økonomi, Datavitenskap og Maskin. 
                            Anvendt Robotikk hører hjemme ved dette fakultetet.
                        </Typography>
                        <Typography variant='body1'>
                            NMBU er kjent for sitt inkluderende og aktive studentmiljø. 
                            Ved universitetet finnes det rundt 90 lag og foreninger med over 1000 medlemmer, 
                            og hvert andre år går studentene sammen for å arrangere <Link href='https://ukaiaas.no/' underline="always">UKA i Ås</Link> (arrangeres neste gang i 2022).
                        </Typography>
                        <Typography variant='body1'>
                            Les mer om det å være student ved NMBU <Link href='https://www.nmbu.no/forside/studieliv' underline="always">her</Link>
                        </Typography>
                        <GoogleMaps/>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}
