import React, {useState} from 'react';
import {TextField, Box, Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
    }
}));


export default function KontaktSkjema() {
    const classes = useStyles();
    const [link, setLink] = useState('');
    const [emne, setEmne] = useState('');
    const [melding, setMelding] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'emne') {
            setEmne(value)
        } else if (name === 'melding') {
            setMelding(value)
        }
        setLink('mailto:robotnik@nmbu.no?subject=' + emne + '&body=' + melding);
    };
    
    
    const handleClick = () => {
        setLink('mailto:robotnik@nmbu.no?subject=' + emne + '&body=' + melding);
    };

    return (
        <React.Fragment>
            <Box className={classes.root} component="form">
                <TextField 
                    className={classes.input} 
                    onChange={handleInputChange} 
                    id="emne"
                    name="emne" 
                    label="Emne" 
                    margin="normal" />
                <TextField 
                    className={classes.input} 
                    onChange={handleInputChange} 
                    id="melding" 
                    name="melding" 
                    label="Melding" 
                    multiline 
                    rows={6} 
                    variant="outlined" 
                    margin="normal" />
                <Button onClick={handleClick} color='primary' variant="contained" href={link}>
                        Send
                </Button>
            </Box>
        </React.Fragment>
    )
}