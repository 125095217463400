import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import {Typography, Grid, Container, Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as all_events from '../events'

const articles = Object.values(Object.values(all_events)[0])


const useStyles = makeStyles(theme => ({
    root: {
    },
    eventsSummary: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down("xs")]: {
            flexDirection: 'column',
        },
    },
    eventsHeader: {
        width: '70%',
        textAlign: 'center',
        [theme.breakpoints.down("xs")]: {
            width: '100%',
        },
    },
    eventsImage: {
        width: "30%", 
        height: "20vh", 
        objectFit: 'cover',
        [theme.breakpoints.down("xs")]: {
            width: '100%',
        },
    },
    paragraph: {
        display: 'block',
        paddingTop: '2vh',
        paddingBottom: '2vh',
        fontSize: 'calc(1.4 * 1rem)',
        fontWeight: '300',
    },
}));

function Article(props) {
    const classes = useStyles();
    return (
        <Accordion elevation={6}>
            <AccordionSummary> 
                <div className={classes.eventsSummary}>
                    <img src={props.img} className={classes.eventsImage} alt="img" />
                    <Typography variant='h3' className={classes.eventsHeader}>{props.header}</Typography>
                    <ExpandMoreIcon />
                </div>
            </AccordionSummary>

            <AccordionDetails>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {
                        props.text.map((paragraph) => {
                            return <>{paragraph}</>
                        })
                    }
                </div>
            </AccordionDetails>
      </Accordion>
    )
}

export default function Eventer() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Container className={classes.root}>
                <Grid container direction="column" spacing={3}>
                    <Grid item className={classes.header}><Typography variant="h2" align="center">Arrangementer</Typography></Grid>
                    {
                        articles.map((article) => {
                            console.log(article)
                            return (
                                <Grid item><Article img={article.img} header={article.header} text={article.text}/></Grid>
                                )
                            })
                        }
                </Grid>
            </Container>
        </React.Fragment>
    )
}