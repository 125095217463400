import React from 'react';
import {Typography} from '@material-ui/core';
import bilde from '../img/hyttetur_23_gruppebilde.jpg';
import { Button } from '@material-ui/core';
const img = bilde

const header = (
    <>
      <Typography variant='h2'>Hyttetur 2024</Typography>
      <Typography variant='h3'>27/09/24 til 29/09/24</Typography>
      <Typography variant='h5'>Påmelding stenger Torsdag 26. Sep 16:00</Typography>
      <Typography variant='h3'><a href="https://maps.app.goo.gl/meWKwyVc5Migbpmy5">Streptokåken</a></Typography>
    </>
  );



const text = [
    <Typography variant='body1'>
        Hei gjengen!{<br/>}{<br/>}

        I år blir det hyttetur på Streptokåken fra 27 September til 29 September.{<br/>}
        Vi har klart å holde årets hytteturen helt gratis. Altså uten egenandel, da turen blir dekket av velferdsmidler.{<br/>}
        Det er kun 20 plasser og her gjelder første mann til mølla prinsippet.{<br/>}
        Avreisetidspunkt kommer senere.{<br/>}{<br/>}

        Påmeldings fristen er satt til Torsdag 26. Sep 16:00.{<br/>}
        Da får vi i styret tid til å kjøpe inn til turen.😊{<br/>}{<br/>}

        Hilsen styret!{<br/>}{<br/>}

    <Button 
        variant="contained" 
        color="primary" 
        size="large" 
        href="https://forms.gle/ufY9c4qjTD4bBz1LA"
        style={{ fontSize: '20px', padding: '20px 40px', marginRight: '20px' }}
    >
    Påmelding
    </Button>
    </Typography>
];

const body = {img, header, text}
export default body