import React from 'react';
import {Typography} from '@material-ui/core';
import thorvald_nmbu from '../img/thorvald_nmbu.jpg';

const img = thorvald_nmbu

const header = 'Velkommen til nettsiden vår!'

const text = [
    <Typography variant='body1'>
        Nå er endelig nettsiden vår oppe og går. 
        Formålet med denne nettsiden er å informere medlemmene våre, 
        interesserte søkere og bedrifter om hva vi holder på med. 
        Her vil det komme oppdateringer om kommende arrangementer, blogginnlegg og stillingsutlysninger.
    </Typography>
]


const body = {img, header, text}
export default body