import React from 'react';
import bilde from '../img/semesterplan_v22.jpg';

const img = bilde

const header = 'Semesterplan V22'

const text = [
    <>
        <img src={bilde} alt='Semesterplan' style={{width: '100%'}}/>
    </>
]

const body = {img, header, text}
export default body