import React from 'react';
import {List, Typography} from '@material-ui/core';
import bilde from '../img/robotnik_styre_2022H.jpg';
import {ListItem} from "@mui/material";

const img = bilde

const header = 'Nytt styre Høsten 2022'

const text = [
    <>
        <Typography variant='body1'>
                21. September avholdt Robotnik NMBU allmøte hvor nye medlemmer ble valgt inn i styre for høsten 2022.
        </Typography>

        <Typography variant='body1'>
            De nye medlemmene i styret er:
        </Typography>

        <List>
            <ListItem>
                <Typography variant='body2'><b>Nima Esat som Markedsførings Ansvarlig.</b></Typography>
            </ListItem>
            <ListItem>
                <Typography variant='body2'><b>Magnus Fidje som Næringslivs Ansvarlig.</b></Typography>
            </ListItem>
            <ListItem>
                <Typography variant='body2'><b>Peder Bukaasen som Økonomi Ansvarlig og Nest Leder.</b></Typography>
            </ListItem>
        </List>

        <Typography variant='body1'>
            Resten av styret fortsetter med Christianie som Sosial ansvarlig, Christine som Leder og William Dahl som administrasjons ansvarlig.
        </Typography>

        <Typography variant='body1'>
            Tusen takk til alle våre medlemmer som møtte opp og brukte stemmeretten sin!
        </Typography>
    </>
]


const body = {img, header, text}
export default body