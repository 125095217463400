import React from 'react';
import {Typography} from '@material-ui/core';
import bilde from '../img/semesterplan_v23.jpg';

const img = bilde

const header = 'Semesterplan v23'

const text = [
    <>

        <Typography variant='h2' align='left' gutterBottom>
            Quiz cup
        </Typography>
        <Typography variant='body1' paragraph>
            Det er tid for Robotniks årlige quizcup!
            Turneringen som lar Robotnikere utfordre hverandres kunnnskaper og quiz ferdigheter!
            Det holdes 5 runder med samenlagt vinner!
        </Typography>
        <Typography variant='h2' align='left' gutterBottom>
            Allmøte
        </Typography>
        <Typography variant='body1' paragraph>
            Robotnik sitt allmøte. Her velges det inn medlemmer av styret og relevante saker og informasjon blir tatt opp.
            Verv som skal velges inn er:    {<br/>}
            - Leder {<br/>}
            - Sosialansvarlig {<br/>}
            - Næringslivsansvarlig  {<br/>}
            - Faddersjef {<br/>}
        </Typography>
        <Typography variant='h2' align='left' gutterBottom>
            Sommeravslutning
        </Typography>
        <Typography variant='body1' paragraph>
        Sommeravslutning må til for å feire alle robotnikerne sin innsats gjennom skoleåret.
        En fin måte å si adjø med hverandre før sommeren kommer. 
        Det blir grilling av pølser og burger, samt inntak av god drikke i tradisjon tro!
        </Typography>
        <img src={bilde} alt='Semesterplan' style={{width: '100%'}}/>
    </>
]

const body = {img, header, text}
export default body