import React from 'react';
import logo from './img/robotnik_logo_wide.png';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, AppBar, Toolbar, Link, Backdrop, Button} from '@material-ui/core';
import Social from './components/social'
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles(theme => ({
    root: {
        background: 'white',
        color: 'black',
        height: '15vh',

    },
    appbar: {
        position: 'sticky',
    },
    icon: {
        padding: '5px'
    },
    logo: {
        height: '15vh',
        fontSize: '50px'
    },
    nav_button: {
        width: '100vw',
        height: '10vh',
    },
    backdrop: {
        top: '15vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: 'white',
    },
    menu: {
        fontSize: '4vh',
        transition: '1s',
        '&:not(:active)': {
            animation: `$menuClick 1s`
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: '6vh',
        },
    },
    "@keyframes menuClick": {
        "0%": {
            transform: `rotate(0deg)`,
        },
        "100%": {
            transform: `rotate(180deg)`,
        }
    },
}));

export default function DenseAppBar() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <React.Fragment>
        <AppBar className={classes.appbar}>
            <Toolbar className={classes.root}>
                <Grid item xs container alignItems='center' direction='row' justifyContent="space-between" className={classes.grid}>
                    <Grid item className={classes.logo}>
                        <Link color="inherit" href="/">
                            <img className={classes.logo} src={logo} style={{height: "100%"}} alt="logo" />
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link color="inherit" onClick={handleToggle}><MenuIcon className={classes.menu}/></Link>
                        <Backdrop
                            open={open}
                            onClick={handleClose}
                            className={classes.backdrop}
                            >
                            <Button href="/" className={classes.nav_button} onClick={handleToggle}><HomeIcon/></Button>
                            <Button href="/for_studenter" className={classes.nav_button} onClick={handleToggle}>For studenter</Button>
                            <Button href="/for_bedrifter" className={classes.nav_button} onClick={handleToggle}>For bedrifter</Button>
                            <Button href="/om_oss" className={classes.nav_button} onClick={handleToggle}>Om oss</Button>
                            <Button href="/om_NMBU" className={classes.nav_button} onClick={handleToggle}>Om NMBU</Button>
                            <Button href="/kontakt_oss" className={classes.nav_button} onClick={handleToggle}>Kontakt oss</Button>
                            <Button className={classes.nav_button}><Social/></Button>
                        </Backdrop>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
        </React.Fragment>
    );
}
