import React from 'react';
import {Typography, List, ListItem} from '@material-ui/core';
import robotnik_styre from '../img/robotnik_styre_2021.jpg';

const img = robotnik_styre

const header = 'Allmøte og nytt styre 2021'

const text = [
    <>
        <Typography variant='body1'>
                Onsdag 21. oktober avholdt Robotnik NMBU allmøte for å velge nytt styre for 2020/2021.
        </Typography>

        <Typography variant='body1'>
            De nye medlemmene i styret er:
        </Typography>

        <List>
            <ListItem>
                <Typography variant='body2'><b>Henrik Nordlie - Næringslivsansvarlig</b></Typography>
            </ListItem>
            <ListItem>
                <Typography variant='body2'><b>Eivind Lid Trøen - Nestleder/økonomiansvarlig</b></Typography>
            </ListItem>
            <ListItem>
                <Typography variant='body2'><b>Jorid Holmen - Administrasjonsansvarlig</b></Typography>
            </ListItem>
        </List>

        <Typography variant='body1'>
            Jakob Bernstein (leder) og Christianie Torres (sosialansvarlig) fortsetter i styret frem til neste allmøte som avholdes på våren.
        </Typography>

        <Typography variant='body1'>
            Vi takker også Filip Lund Andersen, Sigurd Janitz Kvaal og Ingar Eik Høivik for deres innsats som styremedlemmer det siste året.
        </Typography>

        <Typography variant='body1'>
            Tusen takk til alle våre medlemmer som møtte opp og brukte stemmeretten sin!
        </Typography>
    </>
]


const body = {img, header, text}
export default body