import React from 'react';
import {Typography} from '@material-ui/core';
import bilde from '../img/quiz_logo.jpg';
import { Button } from '@material-ui/core';

const img = bilde

const header = (
    <>
      <Typography variant='h2'>Quiz 01 - Robotnik Quiz Cup 2024</Typography>
      <Typography variant='h3'>05/03/24 - 19:00</Typography>
      <Typography variant='h5'>Påmeldingen er stengt</Typography>
        <Typography variant='h3'><a href="https://link.mazemap.com/Bia3YID9">TF3-102</a></Typography>
    </>
  );

const text = [
    <Typography variant='body1'>
        Det er tid for Robotniks årlige quizcup!{<br/>}{<br/>}
        Turneringen som lar Robotnikere utfordre hverandres kunnnskaper og quiz ferdigheter!{<br/>}{<br/>}

        Dette er den første av 5 quizer som vil finne sted i løpet av våren.{<br/>}

        Samle det beste laget og bli med på en kveld med quiz og morro!{<br/>}

        Lagene kan være på maks 5 og man bør være minst 3 personer på et lag.{<br/>}{<br/>}

        Det vil bli servert pizza og brus på arrangementet!{<br/>}{<br/>}


        <Button 
        variant="contained" 
        color="primary" 
        size="large" 
        href="https://forms.gle/zHpR1JkTg7PWNuiU9"
        style={{ fontSize: '20px', padding: '20px 40px', marginRight: '20px' }}
      >
      PÅMELDING
      </Button>
    </Typography>
]

const body = {img, header, text}
export default body