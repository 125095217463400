
import allmøte_v24 from './allmøte_v24'
import quiz_cup_01 from './quiz_cup_01'
import allmøte_h24 from './allmøte_h24'
import hyttetur_24 from './hyttetur_24'

const events = {
    "0": allmøte_h24,
    "1": hyttetur_24,
    "2": quiz_cup_01,
    "3": allmøte_v24
}

export {events}