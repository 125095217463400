import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Grid, Link, Typography} from '@material-ui/core';
import styret from '../img/robotnik_styre_2023H.jpg'
import Styremedlemmer from '../components/styremedlemmer'

const useStyles = makeStyles(theme => ({
  root: {
      position: 'relative',
      maxWidth: '1000px',
  },
  img: {
      height: '50vh',
      [theme.breakpoints.down("xs")]: {
          height: '20vh',
      },
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
  },
  section: {
      paddingTop: '2vh',
      paddingBottom: '2vh',
      borderBottom: '1px solid black',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
  },
}));

export default function DenseAppBar() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container className={classes.root}>
            <Grid justifyContent="center" direction="column" container>
                    <Grid item>
                        <Typography variant='h2' align='center'>Om oss</Typography>
                    </Grid>
                    <Grid item className={classes.img}>
                        <img src={styret} style={{width: "100%", height: "100%", objectFit: 'cover'}} alt='Robotnik NMBU logo'/>
                    </Grid>
                    <Grid item className={classes.section}>
                        <Typography variant='body1'>
                          Robotnik NMBU er linjeforeningen for studenter på femårig master i Anvendt Robotikk ved NMBU. 
                        </Typography>
                    </Grid>
                    <Grid item className={classes.section}>
                        <Typography variant='body1'>
                          Foreningen ble opprettet høsten 2019, samme året som Anvendt Robotikk ble introdusert
                            som ny linje ved fakultet for realfag og teknologi og har som formål å holde sosiale
                            og faglige arrangementer for studenter og bedrifter.
                            På denne måten får studentene økt engasjement og muligheter til nettverksbygging i næringslivet,
                            samtidig som studiet Anvendt Robotikk blir mer ettertraktet.
                        </Typography>
                        <Typography variant='body1'>
                          Styret består av seks medlemmer; Leder, Nestleder/Økonomiansvarlig, Administrasjonsansvarlig,
                            Næringslivsansvarlig, Sosialansvarlig og Markedsføringsansvarlig.
                            Alle styremedlemmer er studenter på Anvendt Robotikk.
                        </Typography>
                        <Typography variant='body1'>
                          Linjeforeningens formål er å holde sosiale og faglige arrangementer, 
                          samt gjøre studiet Anvendt Robotikk mer ettertraktet for både bedrifter og studenter. 
                          Målet er å skape samhold mellom robotikkstudenter, og samtidig gi studentene mulighet 
                          til nettverksbygging med næringslivet. 
                        </Typography>
                        <Typography variant='body1'>
                         Som medlem av foreningen vil du få tilbud om alle typer hendelser,
                            blant annet bedriftsbesøk og presentasjoner, sosiale kvelder og fagrelevante arrangementer.
                            I tillegg jobber foreningen for å forbedre Anvendt Robotikk studiet
                            ved å ta studenters meninger og behov i betraktning.
                        </Typography>
                        <Typography variant='body1'>
                          Følg oss gjerne på 
                          <Link href='https://www.facebook.com/RobotnikNMBU' underline="always"> Facebook </Link>
                          og 
                          <Link href='https://www.instagram.com/robotniknmbu/' underline="always"> Instagram </Link>
                          for nyhetsoppdateringer
                          og innblikk i det som skjer i linjeforeningen. 
                        </Typography>
                        <Styremedlemmer/>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}

