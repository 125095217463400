import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Typography, Grid} from '@material-ui/core';
import profile_picture from '../img/default_profile_picture.png'
import profile_picture_1 from '../img/Styrebilde_Vincent.jpg'
import profile_picture_2 from '../img/Styrebilde_Magnus_N.jpg'
import profile_picture_3 from '../img/Styrebilde_Magnus_F.jpg'
import profile_picture_4 from '../img/Styrebilde_Sebastian.jpg'
import profile_picture_5 from '../img/Styrebilde_William.jpg'
import profile_picture_6 from '../img/Styrebilde_Emilie.jpg'


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down("xs")]: {
            justifyContent: 'center',
        },
    },
    styremedlem: {
        width: '30%',
        paddingBottom: '10%',
        [theme.breakpoints.down("xs")]: {
            width: '90%',
        },
    },
    img: {
        width: '100%',
    },
    header: {
        paddingTop: '10%',
    }
}));

const styremedlemmer = [
                {navn: 'Vincent Kollbotn', img: profile_picture_1, verv: 'Leder for Robotnik'}, 
                {navn: 'Magnus Nyvoll', img: profile_picture_2, verv: 'Sosialansvarlig'}, 
                {navn: 'Magnus Fidje', img: profile_picture_3, verv: 'Næringslivansvarlig'},
                {navn: 'Sebastian Sverkmo', img: profile_picture_4, verv: 'Økonomiansvarlig'},
                {navn: 'William Dahl', img: profile_picture_5, verv: 'Administrasjonsansvarlig'},
                {navn: 'Emilie Petersen', img: profile_picture_6, verv: 'Marketsføringsansvarlig'},
            ];

export default function Styremedlemmer() {
    const classes = useStyles();

    return (
        <React.Fragment>
        <Grid container direction='row' align="center" className={classes.root}>
            {
                styremedlemmer.map((styremedlem) => {return (
                    <Grid item className={classes.styremedlem}>
                        <img className={classes.img} src={styremedlem.img} alt="Bilde" />
                        <Typography variant="h5" className={classes.header}>
                            {styremedlem.navn}
                        </Typography>
                        <Typography variant="h7">
                            {styremedlem.verv}
                        </Typography>
                    </Grid>
                )})
            }
        </Grid>
        </React.Fragment>
    )
}