import React from "react"
import MailIcon from '@material-ui/icons/Mail';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {Box, Link} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        padding: '0.2em',
        fontSize: '4vh',
        transition: '0.3s',
        '&:hover': {
            fontSize: '5vh',
            color: '#429A81',
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: '6vh',
        },
    },
    box: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    }
}));

export default function Social() {
    const classes = useStyles();

    return (
        <Box className={classes.box}>
            <Link color="inherit" href='mailto:robotnik@nmbu.no'>
                <MailIcon className={classes.root}/>
            </Link>
            <Link color="inherit" href='https://www.facebook.com/RobotnikNMBU/'>
                <FacebookIcon className={classes.root}/>
            </Link>
            <Link color="inherit" href='http://instagram.com/robotniknmbu'>
                <InstagramIcon className={classes.root}/>
            </Link>
            <Link color="inherit" href='https://www.linkedin.com/company/robotnik-nmbu/'>
                <LinkedInIcon className={classes.root}/>
            </Link>
        </Box>
    )
}