import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Typography, Grid, Link} from '@material-ui/core';
import KontaktSkjema from '../components/kontakt'
import thorvald_nmbu from '../img/thorvald_nmbu.jpg'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        maxWidth: '1000px',
    },
    img: {
        height: '45vh',
        [theme.breakpoints.down("xs")]: {
            height: '20vh',
        },
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
    },
    section: {
        paddingTop: '2vh',
        paddingBottom: '2vh',
        borderBottom: '1px solid black',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export default function DenseAppBar() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container className={classes.root}>
                <Grid justifyContent="center" direction="column" container>
                    <Grid item>
                        <Typography variant='h2' align='center'>For bedrifter</Typography>
                    </Grid>
                    <Grid item className={classes.img}>
                        <img src={thorvald_nmbu} style={{width: "100%", height: "100%", objectFit: 'cover'}} alt='Student og lærer'/>
                    </Grid>
                    <Grid item className={classes.section}>
                        <Typography variant='body1'>
                            Ønsker deres bedrift å bli bedre kjent med studentene som går Anvendt Robotikk ved NMBU?
                            <br/>   <br/>
                            Vi kan tilrettelegge for bedriftspresentasjoner, plass på stand, 
                            bedriftsbesøk og vi er i tillegg fleksible dersom deres bedrift ønsker å samarbeide med oss.
                        </Typography>
                    </Grid>
                    <Grid item className={classes.section}>
                        <Typography variant='subtitle1'>Bedriftspresentasjon</Typography>
                        <Typography variant='body1'>
                            Robotnik NMBU kan arrangere bedriftspresentasjoner der bedrifter får en unik mulighet til å komme i direkte kontakt med våre studenter. En bedriftspresentasjon består gjerne av generell informasjon om bedriften, hvilke muligheter man har som arbeidstaker, informasjon om sommerjobber og eventuelt annen informasjon man ønsker å gi studentene.
                        </Typography>
                        <Typography variant='body1'>
                            Etter foredraget kan det legges opp til lett servering og kontakt mellom bedriftsrepresentanter og studentene. Vi tar oss av alt det praktiske ved deres besøk, som for eksempel matbestilling, booking av rom, markedsføring og påmelding for studentene.
                        </Typography>
                        <Typography variant='body1'>
                            Vi har også mulighet til å samarbeide med andre linjeforeninger på NMBU, noe som gir muligheten til å arrangere bedriftspresentasjoner på tvers av linjeforeningene.
                        </Typography>
                    </Grid>
                    <Grid item className={classes.section}>
                        <Typography variant='subtitle1'>Karrieredagen</Typography>
                        <Typography variant='body1'>
                            Karrieredagen arrangeres av Næringslivsutvalget ved NMBU (NU) hver høst. Dette er en dag hvor mange bedrifter kommer til NMBU for å promotere seg selv.
                            Se NU sin hjemmeside for mer informasjon: <Link href='https://www.nunmbu.no/' underline='always'>www.nunmbu.no</Link>
                        </Typography>
                    </Grid>
                    <Grid item className={classes.section}>
                        <Typography variant='subtitle1'>Kontakt</Typography>
                        <KontaktSkjema/>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}
