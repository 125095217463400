import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Typography, Box} from '@material-ui/core';
import KontaktSkjema from '../components/kontakt'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        height: '70vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down("xs")]: {
            flexDirection: 'column',
            height: '100%',
        },
    },
    section: {
        width: '30vw',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down("xs")]: {
            marginTop: '5vh',
            marginBottom: '5vh',
            width: '100%',
        },
    },
}));

export default function DenseAppBar() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container className={classes.root}>
                <Box className={classes.section} item>
                    <Typography variant='h2'>Kontakt oss</Typography>
                    <Typography variant='body1'>robotnik@nmbu.no</Typography>
                    <Typography variant='body1'>Postboks 800 NMBU</Typography>
                    <Typography variant='body1'>1433 Ås</Typography>
                </Box>
                <Box className={classes.section} item>
                    <KontaktSkjema/>
                </Box>
            </Container>
        </React.Fragment>
    );
}
